import React, { ChangeEvent, useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import DefaultTable from '../../../components/default/DefaultTable';
import { toast } from 'react-toastify';
import {
    CostCenter,
    CostCenterRuleUpdateForm,
    initialCostCenterRuleUpdateForm
} from '../../../app/models/CostCenter';
import useCostCentersService from '../../../hooks/services/useCostCentersService';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import ModalEditCostCenter from '../components/ModalEditCostCenter';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import ModalCreateCostCenter from '../components/ModalCreateCostCenter';

const CostCentersPage = () => {
    const breadcrumbs = [
        {
            name: 'Centro de costos',
            url: '/cost_centers',
            isActive: true
        }
    ];

    const { fetchingGetCostCenters, getCostCenters } = useCostCentersService();

    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [dataForm, setDataForm] = useState<CostCenterRuleUpdateForm>(
        initialCostCenterRuleUpdateForm
    );

    const [idToEdit, setIdToEdit] = useState(0);
    const [showingEditView, setShowingEditView] = useState(false);
    const [showingCreateView, setShowingCreateView] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const { fetchingEditCostCenter, fetchingCreateCostCenter } = useCostCentersService();

    const init = () => {
        getCostCenters({
            onSuccess: (response) => {
                setDataForm(response.data.cost_center_rule);
                setCostCenters(response.data.cost_centers);
            }
        }).then();
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Centro de costo" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nuevo Centro de Costo"
                        />
                    }
                />

                <DefaultTable
                    data={costCenters}
                    tableLoaded={fetchingGetCostCenters}
                    columns={[
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'type',
                            text: 'Centro de Costo',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'cost_code_id',
                            text: 'Centro de Costo',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'name',
                            text: 'Nombre',
                            sort: true
                        },
                        {
                            classes: '',
                            headerClasses: 'D',
                            dataField: 'society_name',
                            text: 'Sociedad',
                            sort: true,
                            formatter: (cell: any, row: CostCenter) => {
                                if (row.society_name == 'MELT_PIZZA') {
                                    return <div> {'Melt Pizza'} </div>;
                                } else if (row.society_name == 'UNDER_PIZZA') {
                                    return <div> {'Under Pizza'} </div>;
                                } else if (row.society_name == 'FEEL_GOOD') {
                                    return <div> {'Feel Good'} </div>;
                                }
                            }
                        },
                        {
                            classes: '',
                            headerClasses: 'D',
                            dataField: 'commune_id',
                            text: 'Comuna',
                            sort: true,
                            formatter: (cell: any, row: CostCenter) => {
                                return <div> {row.commune.name} </div>;
                            }
                        },
                        {
                            classes: '',
                            headerClasses: 'D',
                            dataField: 'address',
                            text: 'Dirección',
                            sort: true
                        },
                        {
                            classes: '',
                            headerClasses: 'D',
                            dataField: 'remote_relation_id',
                            text: 'Id Externo',
                            sort: true
                        },
                        {
                            dataField: '',
                            text: 'Acciones',
                            classes: 'nowrap-cell nowrap-cell-no-min',
                            headerClasses: 'nowrap-cell nowrap-cell-no-min',
                            formatter: (cell: any, row: CostCenter) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <ButtonTableEdit onClick={() => showEditView(row.id)} />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </DefaultCard>

            {showingCreateView && (
                <ModalCreateCostCenter
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Centro de costo creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el centro de costo');
                    }}
                    disabled={fetchingCreateCostCenter}
                />
            )}

            {showingEditView && (
                <ModalEditCostCenter
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Centro de costo editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el centro de costo');
                    }}
                    handleChange={handleChange}
                    disabled={fetchingEditCostCenter}
                />
            )}
        </>
    );
};

export default CostCentersPage;
