import React from 'react';
import Select, { SingleValue } from 'react-select';
import useFieldError from '../../hooks/useFieldError';

export type FieldSelectOption = {
    value: string | number;
    label: string;
};

type Props = {
    label?: string;
    name: string;
    nameFieldError?: string;
    options: FieldSelectOption[];
    id?: string;
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    showSelectMessage?: boolean;
};

const FieldSelect = ({
    label,
    id,
    name,
    nameFieldError,
    options,
    value,
    onChange,
    errors,
    disabled,
    required,
    className,
    showSelectMessage = true
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);

    const selectedOption = options.find((option) => option.value === value) || null;

    const handleChange = (newValue: SingleValue<FieldSelectOption>) => {
        clearError();
        if (onChange) {
            const syntheticEvent = {
                target: {
                    name: name,
                    id: id ?? name,
                    value: newValue ? newValue.value : ''
                }
            } as unknown as React.ChangeEvent<HTMLSelectElement>;
            onChange(syntheticEvent);
        }
    };

    return (
        <div className={`${className ? className : ''} ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}

            <Select
                inputId={id ?? name}
                name={name}
                value={selectedOption}
                onChange={handleChange}
                onBlur={clearError}
                options={options}
                placeholder={showSelectMessage ? 'Seleccione una opción' : ''}
                isDisabled={disabled}
                classNamePrefix="react-select"
                className={error ? 'is-invalid' : ''}
                isClearable={!required}
            />

            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldSelect;
